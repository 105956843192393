import { Injectable } from '@angular/core';
import { LogLevel, OpenIdConfiguration } from 'angular-auth-oidc-client';
import { GridAppsSettings } from '../../core/config/grid-apps.settings';

@Injectable({ providedIn: 'root' })
export class AuthConfigService {
  constructor(private settings: GridAppsSettings) {}

  getConfig(): OpenIdConfiguration {
    return {
      authority: `https://login.microsoftonline.com/${this.settings.AZ_TENANT}/oauth2/v2.0/authorize`,
      authWellknownEndpointUrl: `https://login.microsoftonline.com/${this.settings.AZ_TENANT}/v2.0/.well-known/openid-configuration`,
      redirectUrl: `${window.location.origin}/auth/callback`,
      postLogoutRedirectUri: `${window.location.origin}/`,
      clientId: this.settings.AZ_BE_CLIENT_ID,
      scope: `openid email offline_access profile api://${this.settings.AZ_BE_CLIENT_ID}/user_impersonation`,
      responseType: 'code',
      silentRenew: true,
      maxIdTokenIatOffsetAllowedInSeconds: 600,
      issValidationOff: true,
      autoUserInfo: false,
      useRefreshToken: true,
      logLevel: LogLevel.Debug,
      customParamsAuthRequest: {
        prompt: 'select_account', // login, consent
      },
      secureRoutes: this.settings.SECURED_ROUTES,
    };
  }
}
