import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, map, Observable, of, shareReplay, switchMap, tap } from 'rxjs';
import { IAuthenticatedUser } from './interfaces/user.interface';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private user$: BehaviorSubject<IAuthenticatedUser | null> = new BehaviorSubject<IAuthenticatedUser | null>(null);
  private userObservable$: Observable<IAuthenticatedUser | null> = this.user$.pipe(shareReplay(1));

  private checkAuth$ = this.oidcSecurityService.checkAuth().pipe(
    switchMap((result) => {
      if (result.isAuthenticated) {
        return this.userService.getDetails().pipe(
          map((user) => {
            // Add the department to roles so that permission checking is easier
            return {
              ...user,
              roles: [...user.roles, `gridapps:dn:department:${user.department}`],
            };
          })
        );
      }
      return of(null);
    }),
    tap((user) => this.user$.next(user)),
    // check auth only once if we use this observable multiple times
    shareReplay(1)
  );

  constructor(private oidcSecurityService: OidcSecurityService, private userService: UserService) {}

  get userData$(): Observable<IAuthenticatedUser | null> {
    return this.userObservable$;
  }

  get user(): IAuthenticatedUser | null {
    return this.user$.value;
  }

  checkAuth(): Observable<IAuthenticatedUser | null> {
    return this.checkAuth$;
  }

  public hasAllRoles(...roles: string[]): Observable<boolean> {
    return this.userObservable$.pipe(
      map((user) => {
        if (!user) {
          return false;
        }
        return roles.every((role) => user.roles.includes(role));
      })
    );
  }

  public hasAtleastOneRole(...roles: string[]): Observable<boolean> {
    return this.userObservable$.pipe(
      map((user) => {
        if (!user) {
          return false;
        }
        return roles.some((role) => user.roles.includes(role));
      })
    );
  }

  public logout() {
    this.user$.next(null);
    this.oidcSecurityService.logoffAndRevokeTokens().subscribe((result) => console.log(result));
  }
}
