import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { GridAppsModule } from './modules/grid-apps.module';

// import ngx-translate and the http loader
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthConfigModule } from './shared/auth/auth-config.module';
import { AuthRoutingModule } from './shared/auth/auth-routing.module';

import { Router } from '@angular/router';
import { AuthInterceptor } from 'angular-auth-oidc-client';
import { AuthService } from './shared/auth/auth.service';
import { SidebarService } from './shared/components/layout/sidebar/sidebar.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    // ngx-translate and the loader module
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    CoreModule,
    GridAppsModule,
    NgbModule,
    FontAwesomeModule,
    AuthConfigModule,
    AuthRoutingModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (sidebarService: SidebarService) => () => sidebarService,
      deps: [Router],
      multi: true,
    },
    {
      /**
       * wait until user data is available before loading the app the auth service will handle authentication
       * and only load additional user data if the user is authenticated
       */
      provide: APP_INITIALIZER,
      useFactory: (auth: AuthService) => () => auth.checkAuth(),
      deps: [AuthService],
      multi: true,
    },
    {
      /**
       * automatically add the token to all secure http requests
       * see {@link AuthConfigService} secureRoutes for the secure routes
       */
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
